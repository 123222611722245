import React, { useState, useEffect } from "react";
import CheckoutDetails from "./CheckoutDetails";
import CTAPopup from "./CTAPopup";
import Select from "react-select";
import { customStyles } from "../../utils/selectStyles";
import PhoneInput from "react-phone-number-input";
import validator from "validator";
import { isValidPhoneNumber } from "react-phone-number-input/mobile";
import { ReactComponent as ErrorIcon } from "../../assets/images/input-error.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/input-success.svg";
import { ReactComponent as Dropdown } from "../../assets/images/dropdown.svg";
import * as api from "../../api";

const GuestsInfo = ({
  user = {},
  event = {},
  tickets = {},
  expiry = null,
  selectedAddons = {},
  subtotal = 0,
  setSubtotal = () => {},
  showReceipt = false,
  setShowReceipt = () => {},
  grandTotal = 0,
  setGrandTotal = () => {},
  nextStep = () => {},
  jumpToStep = () => {},
  newCart = {},
  setNewCart = () => {},
  guestsMapper = [],
  setGuestsMapper = () => {},
  cancelOrder = () => {},
  ticketsPool = {},
  setTicketsPool = () => {},
}) => {
  const [totalTickets, setTotalTickets] = useState(0);
  const [ticketOptions, setTicketOptions] = useState([]);
  const [genderOptions] = useState([
    { label: "Male", value: "m" },
    { label: "Female", value: "f" },
  ]);
  const [expanded, setExpanded] = useState(-1);
  const [userLoading, setUserLoading] = useState(false);
  const [userError, setUserError] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [hideCta, setHideCta] = useState(false);

  const guestTemplate = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: null,
    ticket: null,
    errors: {},
    registered: false,
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    let price = 0;
    for (const ticket in newCart) {
      price += newCart[ticket].quantity * newCart[ticket].price;
    }
    for (const addon in selectedAddons) {
      price += selectedAddons[addon].price * selectedAddons[addon].count;
    }
    setSubtotal(price);
  }, [newCart]);

  useEffect(() => {
    let newOptions = [];
    let allTickets = { ...ticketsPool };
    for (const ticket in allTickets) {
      if (allTickets[ticket].quantity === 0) {
        newOptions = newOptions.filter(
          (item) => item.ticketTier !== allTickets[ticket].ticketTier
        );
      } else {
        newOptions.push({
          label:
            allTickets[ticket].name +
            " (" +
            allTickets[ticket].quantity +
            " remaining)",
          value: allTickets[ticket].id,
          ticketTier: allTickets[ticket].ticketTier,
          price: allTickets[ticket].price,
          name: allTickets[ticket].name,
        });
      }
    }
    setTicketOptions(newOptions);
  }, [ticketsPool]);

  const init = () => {
    let sum = 0;
    let pool = {};
    for (const ticket in tickets) {
      sum += tickets[ticket].quantity;
      if (Object.keys(newCart).length === 0) {
        pool[ticket] = { ...tickets[ticket] };
      }
    }
    setTotalTickets(sum);
    if (Object.keys(newCart).length === 0) {
      let mapper = [];
      let myCart = { ...newCart };
      mapper.push({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.mobile,
        gender: user.gender,
        ticket: null,
        errors: {},
        registered: true,
        pk: user.pk,
      });
      for (let i = 1; i < sum; i++) {
        let newGuest = { ...guestTemplate };
        mapper.push(newGuest);
      }
      if (Object.keys(pool).length === 1) {
        let ticketTier = Object.keys(pool)[0];
        let preselectedTicket = { ...pool[ticketTier] };
        for (let i = 0; i < mapper.length; i++) {
          mapper[i].ticket = {
            label: preselectedTicket.name,
            value: preselectedTicket.id,
            ticketTier: preselectedTicket.ticketTier,
            price: preselectedTicket.price,
            name: preselectedTicket.name,
            quantity: 1,
          };
        }
        myCart[ticketTier] = {
          label: preselectedTicket.name,
          value: preselectedTicket.id,
          ticketTier: preselectedTicket.ticketTier,
          price: preselectedTicket.price,
          name: preselectedTicket.name,
          quantity: mapper.length,
        };
        setNewCart(myCart);
        pool[ticketTier].quantity = 0;
      }
      setTicketsPool(pool);
      setGuestsMapper(mapper);
    }
  };

  const handleSubmit = () => {
    setSubmitError("");
    if (areFormsValid()) {
      setSubmitLoading(true);
      registerUsers();
    }
  };

  const areFormsValid = () => {
    let valid = true;
    let guests = [...guestsMapper];
    for (let i = 0; i < guests.length; i++) {
      let guest = { ...guests[i] };
      let errs = { ...guest.errors };
      if (!guest.email) {
        errs.email = "Email is required";
      }
      if (!guest.firstName) {
        errs.firstName = "First name is required";
      }
      if (!guest.lastName) {
        errs.lastName = "Last name is required";
      }
      if (!guest.ticket) {
        errs.ticket = "Select a ticket";
      }
      if (!guest.gender) {
        errs.gender = "Select gender";
      }
      if (!guest.phone) {
        errs.phone = "Phone is required";
      }
      for (let j = i + 1; j < guests.length; j++) {
        let otherGuest = { ...guests[j] };
        let otherGuestErrors = { ...otherGuest.errors };
        if (
          guest.email &&
          otherGuest.email.toLowerCase() === guest.email.toLowerCase()
        ) {
          otherGuestErrors.email = "This email has already been entered";
        }
        otherGuest.errors = otherGuestErrors;
        guests[j] = otherGuest;
      }
      guest.errors = errs;
      if (Object.keys(guest.errors).length !== 0) {
        valid = false;
      }
      guests[i] = guest;
    }
    if (valid === false) {
      expandGuestWithError();
    }
    setUserError(valid === true ? false : true);
    setGuestsMapper(guests);

    return valid;
  };
  const expandGuestWithError = () => {
    for (let i = 0; i < guestsMapper.length; i++) {
      let errs = { ...guestsMapper[i].errors };
      if (
        Object.keys(errs).length !== 0 ||
        !guestsMapper[i].email ||
        !guestsMapper[i].ticket ||
        !guestsMapper[i].firstName ||
        !guestsMapper[i].lastName ||
        !guestsMapper[i].gender ||
        !guestsMapper[i].phone
      ) {
        setExpanded(i);
        break;
      }
    }
  };

  const handleGuestChange = (index, field, value) => {
    if (field === "email") {
      setUserLoading(true);
    }
    let allGuests = [...guestsMapper];
    let current = { ...allGuests[index] };
    let ticketsCopy = { ...ticketsPool };
    if (field === "ticket") {
      let myCart = { ...newCart };
      if (current.ticket) {
        ticketsCopy[current.ticket.ticketTier].quantity += 1;
        if (myCart[current.ticket.ticketTier].quantity === 1) {
          delete myCart[current.ticket.ticketTier];
        } else {
          myCart[current.ticket.ticketTier].quantity -= 1;
        }
      }
      if (value) {
        ticketsCopy[value.ticketTier].quantity -= 1;
        if (myCart[value.ticketTier]?.quantity) {
          myCart[value.ticketTier].quantity += 1;
        } else {
          myCart[value.ticketTier] = value;
          myCart[value.ticketTier].quantity = 1;
        }
        current[field] = { ...value, label: value.name };
      } else {
        current[field] = null;
      }
      setNewCart(myCart);
    } else {
      current[field] = value;
    }
    let errs = validateOnChange(
      field,
      value,
      { ...current.errors },
      current.registered
    );
    current.errors = errs;
    allGuests[index] = current;

    setTicketsPool(ticketsCopy);
    setGuestsMapper(allGuests);
  };

  const validateOnChange = (field, value, errors) => {
    let errs = { ...errors };
    switch (field) {
      case "email":
        if (!validator.isEmail(value)) {
          errs.email = "Email is invalid";
        } else {
          delete errs.email;
        }
        break;
      case "firstName":
        let firstName = value + "";
        if (!firstName) {
          errs.firstName = "First name is required";
        } else if (!value.match(/^[a-zA-Z]+$/)) {
          errs.firstName = "First name must only contain English letters";
        } else {
          delete errs.firstName;
        }
        break;
      case "lastName":
        let lastName = value + "";
        if (!lastName) {
          errs.lastName = "Last name is required";
        } else if (!value.match(/^[a-zA-Z]+$/)) {
          errs.lastName = "First name must only contain English letters";
        } else {
          delete errs.lastName;
        }
        break;
      case "ticket":
        if (!value) {
          errs.ticket = "Select a ticket";
        } else {
          delete errs.ticket;
        }
        break;
      case "gender":
        if (!value) {
          errs.gender = "Select gender";
        } else {
          delete errs.gender;
        }
        break;
      case "phone":
        let phone = value || "";
        if (!isValidPhoneNumber(phone)) {
          errs.phone = "Phone is invalid";
        } else {
          delete errs.phone;
        }
        break;
      default:
        break;
    }
    return errs;
  };

  const handleDeleteGuest = (index) => {
    let ticketsCopy = { ...ticketsPool };
    let allGuests = [...guestsMapper];
    let current = { ...allGuests[index] };
    if (current?.ticket?.value) {
      let myCart = { ...newCart };
      ticketsCopy[current.ticket.ticketTier].quantity += 1;
      if (myCart[current.ticket.ticketTier]) {
        if (myCart[current.ticket.ticketTier].quantity === 1) {
          delete myCart[current.ticket.ticketTier];
        } else {
          myCart[current.ticket.ticketTier].quantity -= 1;
        }
      }
      setNewCart(myCart);
    }

    allGuests.splice(index, 1);
    if (expanded !== -1) {
      if (index === expanded) {
        setExpanded(-1);
      } else if (index < expanded) {
        setExpanded(expanded - 1);
      }
    }
    setTicketsPool(ticketsCopy);
    setGuestsMapper(allGuests);
  };

  const handleAddGuest = () => {
    let allGuests = [...guestsMapper];
    let newGuest = { ...guestTemplate };
    allGuests.push(newGuest);
    let pool = { ...ticketsPool };
    let myCart = { ...newCart };
    if (Object.keys(pool).length === 1) {
      let ticketTier = Object.keys(pool)[0];
      let preselectedTicket = { ...pool[ticketTier] };
      newGuest.ticket = {
        label: preselectedTicket.name,
        value: preselectedTicket.ticketTier,
        ticketTier: preselectedTicket.ticketTier,
        price: preselectedTicket.price / 100,
        name: preselectedTicket.name,
        quantity: 1,
      };
      myCart[ticketTier].quantity += 1;
      setNewCart(myCart);
      pool[ticketTier].quantity -= 1;
    }
    setTicketsPool(pool);
    setGuestsMapper(allGuests);
  };

  const fetchUser = (index, email) => {
    let allGuests = [...guestsMapper];
    let current = { ...allGuests[index] };
    if (guestsMapper[index]?.errors?.email) {
      setUserLoading(false);
      if (guestsMapper[index]?.email === "") {
        current.registered = false;
        current.firstName = "";
        current.lastName = "";
        current.phone = "";
        current.gender = null;
        current.errors = {};
        delete current.pk;
        allGuests[index] = current;
      }
      setGuestsMapper(allGuests);
      return;
    }
    api.users
      .fetchByEmail(email)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          current.firstName = response.data.firstName;
          current.lastName = response.data.lastName;
          current.phone = response.data.mobileNumber;
          current.gender = response.data?.gender
            ? response.data.gender === "m"
              ? {
                  label: "Male",
                  value: "m",
                }
              : { label: "Female", value: "f" }
            : null;
          current.registered = true;
          current.errors = {};
          current.pk = response.data.pk;
          allGuests[index] = current;
          setGuestsMapper(allGuests);
        } else {
          current.registered = false;
          current.firstName = "";
          current.lastName = "";
          current.phone = "";
          current.gender = null;
          current.errors = {};
          delete current.pk;
          allGuests[index] = current;
          setGuestsMapper(allGuests);
        }
        setUserLoading(false);
      })
      .catch(() => {
        current.registered = false;
        current.firstName = "";
        current.lastName = "";
        current.phone = "";
        current.gender = null;
        current.errors = {};
        delete current.pk;
        allGuests[index] = current;
        setGuestsMapper(allGuests);
        setUserLoading(false);
      });
  };

  const registerUsers = async () => {
    let guests = [...guestsMapper];
    for (let i = 1; i < guests.length; i++) {
      let current = { ...guests[i] };
      if (!current.registered) {
        try {
          let { data } = await api.auth.createUser(
            current.email,
            current.firstName,
            current.lastName,
            current.gender.value,
            current.phone
          );
          current.pk = data.pk;
          guests[i] = current;
          setGuestsMapper(guests);
        } catch (err) {
          if (
            err.response &&
            err?.response?.status === 400 &&
            err?.response?.data?.nonFieldErrors
          ) {
            setSubmitError(err.response.data.nonFieldErrors[0]);
          } else {
            setSubmitError(
              "Something went wrong while registering " + current.email
            );
          }
          setSubmitLoading(false);
          return;
        }
      }
    }
    setSubmitLoading(false);
    nextStep();
  };

  useEffect(() => {
    let guestsArea = document.getElementsByClassName("event-page__guests")[0];
    let guestRow = document.getElementsByClassName("guest-item")[expanded];
    if (guestRow && guestsArea) {
      let scroll = guestRow.offsetTop;
      guestsArea.scroll({ top: scroll });
    }
  }, [expanded]);

  return (
    <React.Fragment>
      <div className="event-page__body">
        <div className="event-page__guests">
          <h4 className="checkout__subtitle">Enter your group information</h4>
          <div className="guests">
            {guestsMapper.map((guest, index) =>
              index === expanded ? (
                <div className="guests__row guest-item" key={index}>
                  <div
                    className="guests__title"
                    onClick={() => setExpanded(-1)}
                  >
                    {index !== 0 && (
                      <button
                        className="btn btn--secondary guests__delete"
                        onClick={(e) => {
                          if (index !== 0) {
                            e.stopPropagation();
                            handleDeleteGuest(index);
                          }
                        }}
                      >
                        Remove
                      </button>
                    )}
                    {index === 0 ? "This is you" : "Guest " + index}
                    <div
                      className="guests__expand"
                      onClick={() => setExpanded(-1)}
                    >
                      <Dropdown className="guests__expand-icon guests__expand-icon--expanded" />
                    </div>
                  </div>
                  <div className="registration-form__input-group--double">
                    <div className="registration-form__input-group">
                      <label
                        htmlFor="email"
                        className="registration-form__label registration-form__label--required"
                      >
                        Email
                      </label>
                      <div className="registration-form__input-box">
                        <input
                          type="text"
                          id="email"
                          className={
                            "registration-form__input" +
                            (guest?.errors?.email
                              ? " registration-form__input--error"
                              : "")
                          }
                          value={guest.email || ""}
                          disabled={index === 0 && user.email ? true : false}
                          onChange={(e) =>
                            handleGuestChange(index, "email", e.target.value)
                          }
                          onFocus={() => setHideCta(true)}
                          onBlur={(e) => {
                            setHideCta(false);
                            fetchUser(index, e.target.value);
                          }}
                        />
                        {guest?.errors?.email && (
                          <ErrorIcon className="registration-form__input-icon guests__status-icon--error" />
                        )}
                        {!guest?.errors?.email && guest.email && (
                          <SuccessIcon className="registration-form__input-icon guests__status-icon--success" />
                        )}
                      </div>
                      {guest?.errors?.email && (
                        <p className="error-message registration-form__input-error-message">
                          {guest.errors.email}
                        </p>
                      )}
                    </div>
                    <div className="registration-form__input-group">
                      <label
                        htmlFor="ticket"
                        id="ticketLabel"
                        className="registration-form__label registration-form__label--required"
                      >
                        Select ticket
                      </label>
                      <div className="registration-form__input-box">
                        {guest.ticket &&
                        Object.keys(ticketsPool).length === 1 ? (
                          <React.Fragment>
                            <input
                              type="text"
                              value={guest.ticket.name}
                              disabled={true}
                              className={
                                "registration-form__input" +
                                (guest?.errors?.ticket
                                  ? " registration-form__input--error"
                                  : "")
                              }
                            />
                            <SuccessIcon className="registration-form__input-icon guests__status-icon--success" />
                          </React.Fragment>
                        ) : (
                          <Select
                            styles={customStyles(
                              guest?.errors?.ticket ? "error" : "default"
                            )}
                            value={guest.ticket || null}
                            options={ticketOptions}
                            onChange={(selected) =>
                              handleGuestChange(index, "ticket", selected)
                            }
                            className="guests__select-input"
                            classNamePrefix="guests__select-input"
                            isClearable
                            isSearchable={false}
                          />
                        )}
                      </div>
                      {guest?.errors?.ticket && (
                        <p className="error-message registration-form__input-error-message">
                          {guest.errors.ticket}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="registration-form__input-group--double">
                    <div className="registration-form__input-group">
                      <label
                        htmlFor="firstName"
                        className="registration-form__label registration-form__label--required"
                      >
                        First Name
                      </label>
                      <div className="registration-form__input-box">
                        <input
                          type="text"
                          id="firstName"
                          className={
                            "registration-form__input" +
                            (guest?.errors?.firstName
                              ? " registration-form__input--error"
                              : "")
                          }
                          value={guest.firstName || ""}
                          disabled={
                            guest.registered && guest.firstName ? true : false
                          }
                          onChange={(e) =>
                            handleGuestChange(
                              index,
                              "firstName",
                              e.target.value
                            )
                          }
                          onFocus={() => setHideCta(true)}
                          onBlur={(e) => setHideCta(false)}
                        />
                        {guest?.errors?.firstName && (
                          <ErrorIcon className="registration-form__input-icon guests__status-icon--error" />
                        )}
                        {!guest?.errors?.firstName && guest.firstName && (
                          <SuccessIcon className="registration-form__input-icon guests__status-icon--success" />
                        )}
                      </div>
                      {guest?.errors?.firstName && (
                        <p className="error-message registration-form__input-error-message">
                          {guest.errors.firstName}
                        </p>
                      )}
                    </div>
                    <div className="registration-form__input-group">
                      <label
                        htmlFor="lastName"
                        className="registration-form__label registration-form__label--required"
                      >
                        Last Name
                      </label>
                      <div className="registration-form__input-box">
                        <input
                          type="text"
                          id="lastName"
                          className={
                            "registration-form__input" +
                            (guest?.errors?.lastName
                              ? " registration-form__input--error"
                              : "")
                          }
                          value={guest.lastName || ""}
                          disabled={
                            guest.registered && user.lastName ? true : false
                          }
                          onChange={(e) =>
                            handleGuestChange(index, "lastName", e.target.value)
                          }
                          onFocus={() => setHideCta(true)}
                          onBlur={(e) => setHideCta(false)}
                        />
                        {guest?.errors?.lastName && (
                          <ErrorIcon className="registration-form__input-icon guests__status-icon--error" />
                        )}
                        {!guest?.errors?.lastName && guest.lastName && (
                          <SuccessIcon className="registration-form__input-icon guests__status-icon--success" />
                        )}
                      </div>
                      {guest?.errors?.lastName && (
                        <p className="error-message registration-form__input-error-message">
                          {guest.errors.lastName}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="registration-form__input-group--double">
                    <div className="registration-form__input-group">
                      <label
                        htmlFor="gender"
                        className="registration-form__label registration-form__label--required"
                      >
                        Gender
                      </label>
                      <div className="registration-form__input-box">
                        {guest.registered ? (
                          <input
                            type="text"
                            value={guest?.gender?.label}
                            disabled={true}
                            className={
                              "registration-form__input" +
                              (guest?.errors?.phone
                                ? " registration-form__input--error"
                                : "")
                            }
                          />
                        ) : (
                          <Select
                            styles={customStyles(
                              guest?.errors?.gender ? "error" : "default"
                            )}
                            options={genderOptions}
                            value={guest.gender || null}
                            onChange={(selected) =>
                              handleGuestChange(index, "gender", selected)
                            }
                            className={
                              "guests__select-input" +
                              (guest?.errors?.gender
                                ? " guests__select-input--error"
                                : guest.gender
                                ? " guests__select-input--success"
                                : "")
                            }
                            classNamePrefix="guests__select-input"
                            isSearchable={false}
                          />
                        )}
                        {guest?.errors?.gender && (
                          <ErrorIcon className="registration-form__input-icon guests__status-icon--error" />
                        )}
                        {!guest?.errors?.gender && guest.gender && (
                          <SuccessIcon className="registration-form__input-icon guests__status-icon--success" />
                        )}
                      </div>
                      {guest?.errors?.gender && (
                        <p className="error-message registration-form__input-error-message">
                          {guest.errors.gender}
                        </p>
                      )}
                    </div>
                    <div className="registration-form__input-group">
                      <label
                        htmlFor="phone"
                        className="registration-form__label registration-form__label--required"
                      >
                        Phone
                      </label>
                      <div className="registration-form__input-box">
                        {guest.registered ? (
                          <input
                            type="text"
                            value={guest.phone}
                            disabled={true}
                            className={
                              "registration-form__input" +
                              (guest?.errors?.phone
                                ? " registration-form__input--error"
                                : "")
                            }
                          />
                        ) : (
                          <PhoneInput
                            international={true}
                            value={guest.phone || ""}
                            onChange={(value) =>
                              handleGuestChange(index, "phone", value)
                            }
                            disabled={
                              guest.registered && guest.phone ? true : false
                            }
                            className={
                              guest?.errors?.phone ? "PhoneInput--error" : ""
                            }
                            onFocus={() => setHideCta(true)}
                            onBlur={(e) => setHideCta(false)}
                          />
                        )}
                        {guest?.errors?.phone && (
                          <ErrorIcon className="registration-form__input-icon guests__status-icon--error" />
                        )}
                        {!guest?.errors?.phone && guest.phone && (
                          <SuccessIcon className="registration-form__input-icon guests__status-icon--success" />
                        )}
                      </div>
                      {guest?.errors?.phone && (
                        <p className="error-message registration-form__input-error-message">
                          {guest.errors.phone}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="guests__collapsed-row guest-item"
                  key={index}
                  onClick={() => setExpanded(index)}
                >
                  <div className="guests__title">
                    {index === 0 ? "You" : "Guest " + index}
                  </div>
                  <div className="guests__status">
                    {guest.email &&
                    guest.ticket &&
                    guest.firstName &&
                    guest.lastName &&
                    guest.gender &&
                    guest.phone &&
                    Object.keys(guest?.errors)?.length === 0 ? (
                      <SuccessIcon className="guests__status-icon guests__status-icon--success" />
                    ) : (
                      <ErrorIcon className="guests__status-icon guests__status-icon--error" />
                    )}
                  </div>
                  <div className="guests__name">
                    {guest.firstName && guest.lastName
                      ? guest.firstName + " " + guest.lastName
                      : "ENTER INFO"}
                  </div>
                  <div className="guests__ticket">
                    {guest?.ticket?.label
                      ? guest.ticket.label
                      : "SELECT TICKET"}
                  </div>
                  <div className="guests__expand">
                    <Dropdown className="guests__expand-icon" />
                  </div>
                  {index !== 0 && (
                    <button
                      className="btn btn--secondary guests__delete"
                      onClick={(e) => {
                        if (index !== 0) {
                          e.stopPropagation();
                          handleDeleteGuest(index);
                        }
                      }}
                    >
                      Remove
                    </button>
                  )}
                </div>
              )
            )}
          </div>
          {guestsMapper.length < totalTickets && (
            <p className="guests__add-btn" onClick={() => handleAddGuest()}>
              + RE-ADD GUEST
            </p>
          )}
        </div>
        <CheckoutDetails
          subtotal={subtotal}
          showReceipt={showReceipt}
          setShowReceipt={setShowReceipt}
          grandTotal={grandTotal}
          event={event}
          tickets={newCart}
          selectedAddons={selectedAddons}
          setGrandTotal={setGrandTotal}
          totalTickets={totalTickets}
          cancelOrder={cancelOrder}
          isHidden={hideCta}
        />
      </div>
      {!submitLoading && !submitError && (
        <CTAPopup
          actionHandler={() => {
            setUserError(false);
            handleSubmit();
          }}
          title=""
          variation={userError ? "error-single" : "single"}
          text={"Next"}
          expiry={userError ? null : expiry}
          disabled={userLoading || Object.keys(newCart).length === 0}
          warning={userError ? "OOPS!" : ""}
          displayMessage={userError ? "Please fill all required fields" : ""}
          isHidden={hideCta}
        />
      )}
      {submitLoading && (
        <CTAPopup
          actionHandler={() => {}}
          variation="no-action"
          displayMessage="Please wait while we process your order..."
          showOverlay={true}
          loading={submitLoading}
          isHidden={hideCta}
        />
      )}
      {!submitLoading && submitError && (
        <CTAPopup
          actionHandler={handleSubmit}
          warning="OOPS!"
          displayMessage={submitError}
          variation="error"
          text="Cancel"
          text2="Retry"
          cancel={() => setSubmitError("")}
          showOverlay={true}
          isHidden={hideCta}
        />
      )}
    </React.Fragment>
  );
};

export default GuestsInfo;
