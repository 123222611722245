import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as api from "../api";
import CheckoutReceipt from "../components/Receipt/CheckoutReceipt";
import { ReactComponent as SuccessIcon } from "../assets/images/order-success-checkmark.svg";
import VisaIcon from "../assets/images/visa-icon.svg";
import MasterCardIcon from "../assets/images/mastercard-icon.svg";
import { options as timeOptions } from "../utils/timeFormat";
import { options as dateOptions } from "../utils/dateFormat";
import { ReactComponent as ErrorIcon } from "../assets/images/input-error.svg";
import { formatNumbers } from "../utils/formatNumbers";
import ShareAndDirections from "../components/Utils/ShareAndDirections";
import { redirect_to, hardcoded_event, currency } from "./../constants";
import CTAPopup from "./../components/Event/CTAPopup";
import PaymentIframe from "./../components/Payment/PayByCard";
import qs from "qs";
import PaymentConfirmationEventBanner from "./../components/Event/PaymentConfirmationEventBanner";

const PresalesReceipt = ({ location = {} }) => {
  const params = useParams();
  const [event, setEvent] = useState({});
  const [grandTotal, setGrandTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [status, setStatus] = useState("");
  const [tickets, setTickets] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [cardPaymentUrl, setCardPaymentUrl] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    let tk = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).token;
    setToken(tk);
    if (params.id && tk) {
      api.orders.getInvitationReceipt(params.id).then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          let ticketsCopy = [];
          let responseItems = response.data.items;
          for (let i = 0; i < responseItems.length; i++) {
            let current = ticketsCopy.findIndex(
              (ticket) => ticket.pk === responseItems[i].ticketTier.pk
            );
            if (current !== -1) {
              ticketsCopy[current].quantity += 1;
            } else {
              ticketsCopy.push({
                name:
                  responseItems[i].ticketTier.name +
                  " " +
                  responseItems[i].ticketTier.nameLine2 +
                  " " +
                  responseItems[i].ticketTier.nameLine3,
                quantity: 1,
                price: responseItems[i].ticketTier.price / 100,
                pk: responseItems[i].ticketTier.pk,
              });
            }
          }
          let eventObj = {
            location: response.data.reservation.event.location,
            locationLink: response.data.reservation.event.locationLink,
            title: response.data.reservation.event.name,
            image: response.data.reservation.event.banner,
            time: new Date(
              response.data.reservation.event.startsAt
            ).toLocaleTimeString([], timeOptions),
            date: response.data.reservation.event.startsAt,
            id: response.data.reservation.event.pk,
          };
          setEvent(eventObj);
          setStatus(response.data.isPaid ? "paid" : "unpaid");
          setTickets(ticketsCopy);
          setReservationId(response.data.reservation.code);
        }
      });
    }
  }, []);
  useEffect(() => {
    getCartTotal();
  }, [tickets]);

  const getCartTotal = () => {
    let sum = 0;
    let price = 0;
    for (let i = 0; i < tickets.length; i++) {
      sum += tickets[i].quantity;
      price += tickets[i].quantity * tickets[i].price;
    }
    setSubtotal(price);
  };

  const handleRetryPayment = () => {
    setPaymentLoading(true);
    api.reservations
      .payReservationAnonymously(reservationId, params.id, "card", token, false)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setCardPaymentUrl(response.data.cardPaymentUrl);
          setPaymentLoading(false);
        }
      })
      .catch(() => {
        setPaymentLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div
        className={
          "payment-confirmation" +
          (status === "unpaid" ? " payment-confirmation--with-action-bar" : "")
        }
      >
        <PaymentConfirmationEventBanner event={event} />
        <div className="payment-confirmation__details">
          <div className="payment-confirmation__title">
            <div className="payment-confirmation__title-container">
              {status === "paid" && (
                <SuccessIcon className="payment-confirmation__success-icon" />
              )}
              {status === "unpaid" && (
                <ErrorIcon className="payment-confirmation__error-icon" />
              )}
              <h3
                className={
                  "payment-confirmation__status" +
                  (status === "paid"
                    ? " payment-confirmation__status-success"
                    : " payment-confirmation__status-error")
                }
              >
                {status === "paid" && "Paid"}
                {status === "unpaid" && "Payment Failed"}
              </h3>
            </div>
          </div>
          {status === "paid" && (
            <p className="payment-confirmation__paragraph">
              Your payment has been confirmed! Check your email to receive your
              {event?.title || ""} Passes.
            </p>
          )}

          <CheckoutReceipt
            tickets={tickets}
            subtotal={subtotal}
            setGrandTotal={setGrandTotal}
            grandTotal={grandTotal}
          />
          <div className="checkout__grand-total">
            <h3 className="checkout__grand-total-title">Grand total</h3>
            <div className="checkout__grand-total-value">
              <p>{formatNumbers(grandTotal)} {currency}</p>
            </div>
          </div>

          {status === "paid" && (
            <div className="payment-confirmation__payment-method">
              <div className="payment-confirmation__payment-method-status">
                <React.Fragment>
                  {status === "paid" && "paid by card"}
                  {status === "unpaid" && "Payment unsuccessful"}
                </React.Fragment>
                <div>
                  <img
                    src={VisaIcon}
                    className="payment-confirmation__payment-method-image"
                    alt="card"
                  />
                  <img
                    src={MasterCardIcon}
                    className="payment-confirmation__payment-method-image"
                    alt="card"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <PaymentIframe
          url={cardPaymentUrl}
          close={() => {
            setCardPaymentUrl("");
            setPaymentLoading(false);
          }}
          setLoading={setPaymentLoading}
          loading={paymentLoading}
        />
      </div>
      {status === "unpaid" && (
        <CTAPopup
          actionHandler={() => {
            handleRetryPayment();
          }}
          text="Retry Payment"
        />
      )}
    </React.Fragment>
  );
};

export default PresalesReceipt;
