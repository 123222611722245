import React from "react";
import { ReactComponent as Lock } from "../../assets/images/lock-grey.svg";

const Stepper = ({ steps = [], active = null, handleStepClick = () => {} }) => {
  return (
    <div className="stepper">
      {steps.map((step, index) => (
        <div
          className={
            "stepper__step" +
            (active === index ? " stepper__step--active" : "") +
            (index < active ? " stepper__step--completed" : "") +
            (index < steps.length - 1 ? " stepper__step--with-bar":"")
          }
          key={index}
          onClick={() => handleStepClick(index)}
        >
          <div
            className={
              "stepper__step-label" +
              (index < active && !step.visitable
                ? " stepper__step-label--locked"
                : "")
            }
          >
            {step.label}
          </div>
          {index < steps.length - 1 && <div className="stepper__step-bar">
            
            </div>}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
